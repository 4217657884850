import React from "react";
import queryString from "query-string";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";
import SEO from "@src/components/SEO";
import { incrementMetric } from "@src/utils/logClient";

const ClassPhotobook: React.FC = () => {
  useRedirectHandler(() => {
    const parsedLocation = queryString.parse(window?.location?.search || "");
    const { classID } = parsedLocation;

    if (!classID) {
      incrementMetric("external_site.universal_link.fallback", { component: "ClassPhotobook" });
      return `https://home.classdojo.com/#/story`;
    }
    return `https://home.classdojo.com/#/class-photobook/${classID}`;
  });

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};

export default ClassPhotobook;
